<template>
  <v-container class="fill-height">
    <v-card flat class="justify-center pa-2">
      <v-form @submit.prevent="userLogin">
        <v-card class="mx-auto pa-6 pb-8" elevation="3" rounded="lg" :max-width="cardMaxWidth">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" md="4" class="d-flex justify-center mb-4 mb-md-0" v-if="faviconImage || lightImage">
              <v-img
                :src="logoImage"
                alt="Protected"
                :max-height="imageMaxHeight"
                :max-width="imageMaxWidth"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="8">
              <div>
                <div class="text-subtitle-2 text-medium-emphasis">Email</div>
                <v-text-field
                  :density="textFieldDensity"
                  placeholder="Email address"
                  prepend-inner-icon="mdi-email-outline"
                  v-model.trim="user.email"
                  required
                  solo
                  outlined
                  :tabindex="1"
                  :success="isFieldValid(user.email)"
                ></v-text-field>

                <div class="text-subtitle-2 text-medium-emphasis d-flex align-center justify-space-between">
                  Password
                  <a
                    class="text-caption text-decoration-none text-blue"
                    href="/forgot-password"
                    rel="noopener noreferrer"
                    target="_blank"
                    :tabindex="4"
                  >
                    Forgot login password?
                  </a>
                </div>
                <v-text-field
                  :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'"
                  :density="textFieldDensity"
                  placeholder="Enter your password"
                  prepend-inner-icon="mdi-lock-outline"
                  @click:append="visible = !visible"
                  v-model.trim="user.password"
                  solo
                  outlined
                  :tabindex="2"
                  :success="isFieldValid(user.password)"
                ></v-text-field>
                <v-btn
                  class="my-2"
                  :size="buttonSize"
                  color="primary"
                  rounded
                  block
                  type="submit"
                  :loading="loginLoading"
                  :disabled="loginLoading"
                  :tabindex="3"
                >
                  <span v-if="!loginLoading">Log In</span>
                  <v-progress-circular
                    v-if="loginLoading"
                    indeterminate
                    color="white"
                    size="24"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-card>

    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import useLocalStorage from "../../composables/useLocalStorage";
const fb = require("../../firebaseDb.js");

export default {
  data() {
    return {
      visible: false,
      user: {
        email: "",
        password: "",
      },
      loginLoading: false,
      response: "",
      snackColor: "black",
      snackbar: false,
      lightImage: null,
      faviconImage: null,
    };
  },
  computed: {
    ...mapGetters(["darkTheme"]),
    imageMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '100px';
    },
    imageMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '200px' : '100px';
    },
    cardMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '700px' : '100%';
    },
    textFieldDensity() {
      return this.$vuetify.breakpoint.mdAndUp ? 'compact' : 'comfortable';
    },
    buttonSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 'large' : 'small';
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },
  created() {
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightImageStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this. lightImage = lightImageStorage.get();
    if (this.$store.state.currentUser) {
      this.$router.replace('/');
    }
  },
  methods: {
    isFieldValid(fieldValue) {
      return !!fieldValue && fieldValue.length > 0;
    },
    async userLogin() {
      this.loginLoading = true;
      try {
        const res = await fb.auth.signInWithEmailAndPassword(this.user.email, this.user.password);
        this.$store.commit("setCurrentUser", res.user);
        await this.$store.dispatch("fetchUserProfile");
        this.$router.push("/");
      } catch (error) {
        this.snackbar = true;
        this.snackColor = "error";
        this.response = error.message;
      } finally {
        this.loginLoading = false;
      }
    },
  },
};
</script>
